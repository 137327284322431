import React from "react"

import Layout from "../../components/Layout"
import ContactForm from "../../components/ContactForm"

export default function contact() {
  return (
    <Layout>
      <ContactForm />
    </Layout>
  )
}
